<template>
  <div>
    <content-background-cover
      background-color='#FDFCFD'
      class='z-0'
      :top-offset='0' />
    <main-hero-section />
    <main-important-dates
      :important-dates='importantDates'
      :sidebar-bg-container-configs='sidebarConfigs'
      main-card-width='14rem' />
    <div class='relative mt-16'>
      <content-background-cover
        background-color='#FFF'
        class='z-0 border-t border-b'
        :top-offset='0' />
      <welcome-sponsors-carousel />
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import MainHeroSection         from '@/components/MainHeroSection.vue'
import MainImportantDates      from '@/components/MainImportantDates.vue'
import WelcomeSponsorsCarousel from '@/components/welcome/WelcomeSponsorsCarousel.vue'

export default {
  name: 'Main',
  components: {
    ContentBackgroundCover,
    MainHeroSection,
    MainImportantDates,
    WelcomeSponsorsCarousel,
  },
  data () {
    return {
      sidebarConfigs: {
        title: 'Important Dates',
        height: '580px',
        barStyle: 'linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%); color: #FFF;'
      },
      importantDates: [
        {
          monthString: 'August',
          dayString: '12',
          title: 'Completed Papers or Extended Abstracts Submission Deadline',
          description: ''
        },
        {
          monthString: 'August',
          dayString: '22',
          title: 'Presentation Schedule Release Date',
          description: ''
        },
        {
          monthString: 'September',
          dayString: '26',
          title: 'Offline & Online Pre-registration Deadline',
          description: ''
        },
        {
          monthString: 'September',
          dayString: '30',
          title: 'Presentation File Submission Deadline',
          description: ''
        },
        {
          monthString: 'October',
          dayString: '7',
          title: 'Online Only Pre-registration Deadline',
          description: ''
        }
      ],
    }
  },
}
</script>
